import React, { useState, useEffect } from 'react'
import { array } from 'yup'
import chairman from '../assets/images/chairman.jpg'
import http from '../_api/http'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import { useLocation } from '@reach/router'

const BlogbyId = () => {
  const location = useLocation()

  const [loading, setLoading] = useState(true)
  const [item, setBlogDetails] = useState({})
  const PUBLIC_URL = process.env.REACT_APP_APIURL
  useEffect(() => {
    let url_string = location.href
    let url = new URL(url_string)
    let id = url.searchParams.get('id')
    http.get('api/blog/ApiCallBlogList?id=' + id).then(res => {
      setBlogDetails(res.data)
      setLoading(false)
    })
  }, [])
  return (
    <section className='team-details'>
      <div className='container'>
        {loading ? (
          <h4>View Blog Loading...</h4>
        ) : (
          <>
            <div className='row justify-content-between'>
              <div className='col-lg-5'>
                <div className='team-details__content'>
                  <h2 className='team-details__title title'>
                    {item.blog_title}
                  </h2>
                  <p dangerouslySetInnerHTML={{ __html: item.blog_description }} className='team-details__text'></p>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='team-one__single'>
                  <div className='team-one__image'>
                    <img src={PUBLIC_URL + 'BlogImage/' + item.image}
                      alt='Error'
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default BlogbyId
