import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeaderAbout from "../components/PageHeaderAbout";
import Footer from "../components/Footer";
import BlogbyId from "../components/BlogbyId";
import Topbar from "../components/Topbar";

const BlogPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Blog">
            <Topbar />
            <NavOne />
            <PageHeaderAbout title="Blog" />
            <BlogbyId />
            <Footer />
        </Layout>
    );
};

export default BlogPage;
